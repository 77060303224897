import React, {useEffect} from "react";

export const Printable =React.forwardRef(({docInfor,patientInfor,HandlePrev}) => { 
    // const [seen, setSeen] = useState(true);
  useEffect(() => {
    window.print()
  });
    return (  
        <>
        <div className="container">
            <div className="row">
                <div className="col-xl-2 col-md-6"></div>
                <div className="col-xl-8 col-md-6">
                <br />
              <div className="container">
              <div className="text-primary text-center display-6"><strong>DR SCREENING</strong></div>
              <div className="display-5 text-center">Medical Results</div>
             <hr />

         <div className="container shadow">
         <div className="container">
         <div className="text-primary text-center display-5"> 
         <strong>Patient Data</strong>          
        </div>
        </ div>
        <div className="container">          
             {/*  <tr style={{backgroundColor: "#000"}}> */}
        
            <table className="table" style={{borderRadius: "10px", margin: "5px"}}>
            <tr>
                <td><strong>Patient Name:</strong></td>
                <td>{patientInfor?.name}</td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Age</strong></td>
                <td>{patientInfor?.age}</td>
            </tr>
            <tr>
                <td><strong>Sex</strong></td>
                <td>
                {patientInfor?.sex}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Visual Acuity</strong></td>
                <td>
                    <form  className="row">
                    <span col-xl-6 col-md-6>
                    Left: {patientInfor?.visualAcuityLeft}</span>  
                    <span col-xl-6 col-md-6>
                    Right: {patientInfor?.visualAcuityRight}</span>
                    
                    </form>
                </td>
            </tr>
            <tr>
                <td className="text-primary"><strong>height</strong></td>
                <td>
                {patientInfor?.height}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Weight</strong></td>
                <td>
                {patientInfor?.weight}
                </td>
            </tr>
            <tr>
                <td><strong>Smoker Status</strong></td>
                <td>
                {patientInfor?.smokerStatus}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Diabetes type</strong></td>
                <td>
                {patientInfor?.diabatesType}
                </td>
            </tr>
            <tr>
                <td><strong>Diabates Diagnosis Date</strong></td>
                <td>
                {patientInfor?.diabatesCheckDate}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Diabates Medication</strong></td>
                <td>
                {patientInfor?.daibatesMedication}
                </td>
            </tr>
            <tr>
                <td><strong>Hypertensive?</strong></td>
                <td>
                {patientInfor?.Hypertensive}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Cardiovascular Disease? </strong></td>
                <td>
                {patientInfor?.CardiovascularDisease}
                </td>
            </tr>
            <tr>
                <td><strong>OTC Signal Strength</strong></td>
                <td>
                <form  className="row">
                    <span col-xl-6 col-md-6>
                    Left: 
                    {patientInfor?.OTCSleft}
                    </span> 
                    <span col-xl-6 col-md-6>
                    Right: {patientInfor?.OTCSright}
                    </span>
                    </form>
                </td>
            </tr>
            </table>
        </div>

        <div className="container">
        <div className="text-primary text-center display-6"> 
            <strong> Blood Pressure and Biochemistry</strong>
            </div>
        </div>
          
          <div className="container">
          <table className="table" style={{borderRadius: "10px", margin: "5px"}}>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong> Systolic Blood Pressure</strong></td>
                <td>
                {patientInfor?.SBP}
                </td>
            </tr>
            <tr className="text-primary">
                <td><strong> Diastolic Blood Pressure</strong></td>
                <td>
                {patientInfor?.DBP}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong> Random Blood Glucose</strong></td>
                <td>
                {patientInfor?.RBG}
                </td>
            </tr>   
            <tr className="text-primary">
                <td><strong> HbA1C</strong></td>
                <td>
                {patientInfor?.HbA1C}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong> Total Cholestrol </strong></td>
                <td>
                {patientInfor?.Cholestrol}
                </td>
            </tr>
            <tr className="text-primary">
                <td><strong> LDL</strong></td>
                <td>
                {patientInfor?.LDL}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>HDL</strong></td>
                <td>
                {patientInfor?.HDL}
                </td>
            </tr>
            <tr className="text-primary">
                <td><strong> Triglycerides</strong></td>
                <td>
                {patientInfor?.Triglycerides}
                </td>
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong> Albuminuria? </strong></td>
                <td>
                {patientInfor?.Albuminuria}
                </td>
            </tr>       
          
          </table>
          <br />
          </div>
          </div>
          </div>
          <div className="container">
            
          </div>
          <div className="container">
          <table className="table shadow" style={{borderRadius: "10px", margin: "5px"}} >
        <tr>
                <th className="text-primary"><strong>Final Grading </strong></th>
                <th className="text-primary rowSpecs"><strong>Right Eye</strong></th>
               <th className="text-primary"><strong>Left Eye</strong></th>
        </tr>
        <tr style={{backgroundColor: "#f9f9f9"}}>
                <td /* className="RowHead" */><strong>Retinopathy (Highest grade per eye)</strong></td>
                <td className="rowSpecs">
                 {localStorage.getItem("RRight1") && localStorage.getItem("RRight1")}
                 {localStorage.getItem("RRight2") && localStorage.getItem("RRight2")}
                 {localStorage.getItem("RRight3") && localStorage.getItem("RRight3")}
                 {localStorage.getItem("RRight4") && localStorage.getItem("RRight4")}
                 {localStorage.getItem("RRight5") && localStorage.getItem("RRight5")}
                </td>
                <td>
                  {localStorage.getItem("RLeft1") && localStorage.getItem("RLeft1")}
                 {localStorage.getItem("RLeft2") && localStorage.getItem("RLeft2")}
                 {localStorage.getItem("RLeft3") && localStorage.getItem("RLeft3")}
                 {localStorage.getItem("RLeft4") && localStorage.getItem("RLeft4")}
                 {localStorage.getItem("RLeft5") && localStorage.getItem("RLeft5")}</td>
           </tr>
           <tr>
                <td><strong>Maculopathy</strong></td>
                <td className="rowSpecs">
                {localStorage.getItem("MRight") && localStorage.getItem("MRight")}
                    </td>
                <td>   
                {localStorage.getItem("MLeft") && localStorage.getItem("MLeft")}
               </td>
              </tr>
              <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>OCT</strong></td>
                <td className="rowSpecs">
                {localStorage.getItem("ORightp") && localStorage.getItem("ORightp")}
                 {localStorage.getItem("ORightn") && localStorage.getItem("ORightn")}
                 {localStorage.getItem("ORightb") && localStorage.getItem("ORightb")}
                </td>
                <td>
                {localStorage.getItem("OLeftp") && localStorage.getItem("OLeftp")}
                 {localStorage.getItem("OLeftn") && localStorage.getItem("OLeftn")}
                 {localStorage.getItem("OLeftb") && localStorage.getItem("OLeftb")}
                </td>
              </tr>  
          </table>
          </div>
          <div className="container">
            <table className="table shadow" style={{borderRadius: "10px", margin: "5px"}}>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Management Plan<br/>
                (Either continue annual<br/> screening, OCT surveillance<br/> 6-12m or Refer)</strong></td>
                <td>
                {docInfor?.Medicalplan}
                </td>
            </tr>
            <tr>
                <td><strong>Name of Clinician</strong></td>
                <td>
                {docInfor?.name}
                </td>
                
            </tr>
            <tr style={{backgroundColor: "#f9f9f9"}}>
                <td><strong>Signature</strong></td>
                <td>
                {docInfor?.sign}

                </td>
                
            </tr>

            </table>             
            
          </div>
                </div>
                <div className="col-xl-2 col-md-6"></div>
            </div>
            
        </div>
      {/* {seen && <div className="ButtonSection">
       
        <button
            onClick={()=>{HandlePrev()}}
            >Back</button>
            <button
            onClick={()=>{
                setSeen(false)
                window.print()

            }}
            > confirm and Print</button>
           
          </div>} */}
       </>
    
    );
 });


