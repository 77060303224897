import React, {  useState } from "react";
import "./ExampleRM.css";

function ExampleRM({handleNext,handlePrev}) { 
//state

const HandleNextin =()=>{
 
 handleNext({})
}
const HandlePrevIn =()=>{
 
  handlePrev({})
}


  
    return (  
        <> 
        <div className="nav navbar-expand-lg sticky-top bg-gradient shadow text-center" style={{marginBottom: "10px", backgroundColor: "#0000ff"}}>  
        <div className="container">
            <p className="display-6 text-light text-center">
              <strong>OTC Examples: R1M1</strong>
              </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="container">
            <span className="SideLabel bg-gradient shadow">R1M1</span>
            </div>
            </div>
            <div className="col-xl-8 col-md-6">
            <div className="shadow">
          <img  className="img-fluid" style={{ height: "auto"}} src={require('../../assests/Images/rm2.png')} alt="retina" />         
         
          </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
                <div className="row">
                <div className="col-xl-4 col-md-6"></div>
                <div className="col-xl-4 col-md-6 text-center">
                <button className="btn btn btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button className="btn btn btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Next</button>
                </div>
                
                
                
                <div className="col-xl-4 col-md-6"></div>
              </div>
            </div>
            <br /><br />
        </>
        
      );

    }


 
export default ExampleRM;