import React, {  useState, useEffect } from "react";
import "./RetinoOne.css";

function RetinoOne({leftSelection,
  rightSelection,
  handleNext,handlePrev}) { 
//state
const [leftChecked, setleftChecked] = useState(false);
const [rightChecked, setRightChecked] = useState(false);
const [leftPick, setLeftPick] = useState(leftSelection);
const [rightPick, setRightPick] = useState(rightSelection);




useEffect(() => {
  if("RO" in leftPick ){
    setleftChecked(true)
  }
  if("RO" in rightPick ){
    setRightChecked(true)
  } 
});

const HandleNextin =()=>{
  var data ={
    Rleft: leftPick,
    Rright: rightPick
  }
 handleNext(data)
 if(rightChecked){
  localStorage.setItem("RRight1",
   "Diabetic Retinopathy Absent")
 }else{
  localStorage.setItem("RRight1",
  "")
  //console.log("her")
 }
 if(leftChecked){
  localStorage.setItem("RLeft1",
   "Diabetic Retinopathy Absent")
 }else{
  localStorage.setItem("RLeft1",
   "")
 }
}

const HandlePrevIn =()=>{
  var data ={
    Rleft: leftPick,
    Rright: rightPick
  }
  handlePrev(data)
  if(rightChecked){
    localStorage.setItem("RRight1",
     "Diabetic Retinopathy Absent")
   }else{
    localStorage.setItem("RRight1",
     "")
   }
   if(leftChecked){
    localStorage.setItem("RLeft1",
     "Diabetic Retinopathy Absent")
   }
   else{
    localStorage.setItem("RLeft1",
     "")
   }
   
}
const handleLeftChange=(pick)=>{
 
  if(("RO" in leftPick) || Object.keys(leftPick).length===0){
    //add or remove it
    if("RO" in leftPick){
      setLeftPick({})
      setleftChecked(false)
    }
   if(Object.keys(leftPick).length===0){
    setLeftPick({RO:pick})
    setleftChecked(true)
   }
  }
   if(!("RO" in leftPick )&& Object.keys(leftPick).length>0){
    alert("You already selected Retinopathy Grade")
  } 
}
const handleRightChange=(pick)=>{

  if( ("RO" in rightPick) || Object.keys(rightPick).length===0){
    //add or remove it
    if("RO" in rightPick){
      setRightPick({})
      setRightChecked(false)
    }
   if(Object.keys(rightPick).length===0){
    setRightPick({RO:pick})
    setRightChecked(true)
   }
  }
  if(!("RO" in rightPick )&& Object.keys(rightPick).length>0){
    alert("You already selected Retinopathy Grade")
  } 
}

    return ( 
      <> 
        {/* <div  className="dashboard container"> */}
           
        <div className="nav navbar-expand-lg sticky-top shadow text-center" style={{marginBottom: "10px", backgroundColor: "#000"}}>
          <div className="container">
            <p className="display-6 text-light text-center">
            R0: No signs of diabetic retinopathy
            </p>
          </div>          
        </div>
        <div className="container">
        <div className="container">
        <div className="row">
          <div className="Image shadow col-xl-4 col-md-6">
            <img  className="HeaderSide img-fluid" style={{ height: "auto"}} src={require('../../assests/Images/retino1.jpg')} alt="retina" />
            <div className="Caption text-primary">
              <h5>Source: DDR Dataset</h5>
            </div>
          </div>
          <div className="SideTable col-xl-8 col-md-6">
            <div className="container">
              <table className="shadow" style={{borderRadius: "10px", margin: "5px"}}>
              <tr style={{backgroundColor: "#000"}}>
                <th>
                Criteria
                </th>
                <th>Right Eye
               (tick if present)</th>
               <th>Left Eye
               (tick if present)</th>
              </tr>
              <tr>
                <td className="text-dark">No signs of diabetic retinopathy</td>
                <td><form>
                <input type="checkbox"
                 value="diabeticRetinopathy" 
                checked={rightChecked} 
                className="CheckBox" id="1" 
                onChange={(e) => {handleRightChange(e.target.value)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox"  
                 value="diabeticRetinopathy" 
                 checked={leftChecked} 
                className="CheckBox" 
                id="1" 
                onChange={(e) => {handleLeftChange(e.target.value)}}/>
                  </form></td>
              </tr>
              
              </table>
              <span className="">
                <span  className="RigthAnswer bg-primary text-light">Right eye:</span>
                <span className="AnswerDisplay">{rightChecked?"Diabetic Retinopathy Absent":""}</span>
              </span>
              <span>
                <span  className="LeftAnswer bg-dark text-light">Left eye:&nbsp;&nbsp;&nbsp;</span>
                <span  className="AnswerDisplay">{leftChecked?"Diabetic Retinopathy Absent":""}</span>
              </span>
              
            </div>
          </div>
          <div className="container">
                <div className="row">
                <div className="col-xl-4 col-md-6"></div>
                <div className="col-xl-4 col-md-6 text-center">
                <button className="btn btn btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button className="btn btn btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Next</button>
                </div>
                
                
                
                <div className="col-xl-4 col-md-6"></div>
              </div>
            </div>
        </div>
        </div>
        </div>
        {/* </div> */}
        </>
      );

    }


 
export default RetinoOne;