import React, {  useState } from "react";
import "./Firstform.css";
// import MyImage from '../../assests/Images/LogoICEID.png';


const getDate =()=>{
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + '-' + dd + '-' + yyyy;
  return today
}
function Firstform({callBack, patientInfor}) { 
    // state zero
    const [name, setName] = useState(patientInfor?.name ? patientInfor?.name :"" )
    const [age, setAge] = useState(patientInfor?.age ? patientInfor?.age :"")
    const [sex, setSex] = useState(patientInfor?.sex ? patientInfor?.sex :"")
    const [visualAcuityL, setvisualAcuityL] = useState(patientInfor?.visualAcuityLeft ? patientInfor?.visualAcuityLeft :"")
    const [visualAcuityR, setvisualAcuityR] = useState(patientInfor?.visualAcuityRight ? patientInfor?.visualAcuityRight :"")
    const [height, setHeight] = useState(patientInfor?.height ? patientInfor?.height :"")
    const [weight, setWeight] = useState(patientInfor?.weight ? patientInfor?.weight :"")
    const [smoker, setSmoker] = useState(patientInfor?.smokerStatus ? patientInfor?.smokerStatus :"")
    const [diaType, setdiaType] = useState(patientInfor?.diabatesType ? patientInfor?.diabatesType :"")
    const [diaDate, setDiaDate] = useState(patientInfor?.diabatesCheckDate ? patientInfor?.diabatesCheckDate :"")
    const [daiMed,setDiaMed] = useState(patientInfor?.daibatesMedication ? patientInfor?.daibatesMedication :"");
    const [Hypertensive, setHypertensiveL] = useState(patientInfor?.Hypertensive ? patientInfor?.Hypertensive :"")
    const[Cardiovascular ,setCardiovascular] = useState(patientInfor?.CardiovascularDisease ? patientInfor?.CardiovascularDisease :"")
    const[OTCSleft,setOTCleft] = useState(patientInfor?.OTCSleft ? patientInfor?.OTCSleft :"")
    const [OTCSright, setOTCSright] = useState(patientInfor?.OTCSright ? patientInfor?.OTCSright :"")
    const [SBP, setSBP] = useState(patientInfor?.SBP ? patientInfor?.SBP :"")
    const [DBP, setDBP] = useState(patientInfor?.DBP ? patientInfor?.DBP :"")
    const [RBG, setRBG] = useState(patientInfor?.RBG ? patientInfor?.RBG :"")
    const [HbA1C, setHbA1C] = useState(patientInfor?.HbA1C ? patientInfor?.HbA1C :"")
    const [Cholestrol, setCholestrol] = useState(patientInfor?.Cholestrol ? patientInfor?.Cholestrol :"")
    const [LDL, setLDL] = useState(patientInfor?.LDL ? patientInfor?.LDL :"")
    const [HDL, setHDL] = useState(patientInfor?.HDL ? patientInfor?.HDL :"")
    const [Triglycerides, setTriglycerides] = useState(patientInfor?.Triglycerides ? patientInfor?.Triglycerides :"")
    const [Albuminuria,setAlbuminuria] = useState(patientInfor?.Albuminuria ? patientInfor?.Albuminuria :"")
    const [consent, setConsent] = useState(patientInfor?.consent ? patientInfor?.consent:false )
    const [noconsent, setnoConsent] = useState(false)

    const handleNext =()=>{
        //all fields should be filled
        const data ={
            name,
            age,
            sex,
            visualAcuityLeft:visualAcuityL,
            visualAcuityRight: visualAcuityR,
            height,
            weight,
            smokerStatus:smoker,
            diabatesType:diaType,
            diabatesCheckDate:diaDate,
            daibatesMedication:daiMed,
            Hypertensive,
            CardiovascularDisease:Cardiovascular,
            OTCSleft,
            OTCSright,
            SBP,
            DBP,RBG,HbA1C,Cholestrol,
            LDL,HDL,Triglycerides,Albuminuria ,consent
        }
        //console.log(data)
        if(name !=="" && age  !=="" ){
            if(age>120){
                alert("Fill proper age") 
                return
            }
            if(SBP>300){
                alert("Fill proper blood pressure") 
                return
            }

            callBack(data)
            
        }else{
            alert("fill all fields please")
        }


    }



    return (
        <div className="dashboard container-fluid">     
         <div className="HeaderSection nav">

            <div className="LeftHeader">
            <div>
            <img  className="ImageHeader" src={require('../../assests/Images/LogoICEID.png')} alt="IASON" />
            <img  className="ImageHeadereye" src={require('../../assests/Images/eyeicon.png')} alt="IASON" />
            </div>
             </div>
             <div className="RightHeader">
            {getDate()}
            <img  className="QRImageHeader" src={require('../../assests/Images/qrcode2.png')} alt="QR" />
             </div>
          </div>
          <div className="SubHeading display-3"> DR SCREENING</div>
          <div className="SmallHeading display-6"> Medical History Data</div><br />
        <div className="row container-fluid text-center">
            <div className="col-xl-2 col-md-6"></div>
            <div className="Concent shadow col-xl-8 col-md-6">
                <div className="container-fluid row">
                    <div className="ConcentHead bg-primary col-xl-3 col-md-6">
                        <span className="display-6 text-light text-center">Consent</span>
                    </div>
                    <div className="ConcentText col-xl-6 col-md-6">
                        <div className="container">
                        <h5 className="text-primary">
                        
                            I consent to the use of my anonymised data for research
                             and audit purposes, including the publication of 
                            aggregate results and sharing of anonymised data with other researchers
                                                                            
                        </h5>
                        </div></div>
                    <div className="col-xl-3 col-md-6">
                        <div className="container row">
                            <div className="ConcentBox col">
                        <span className="text-primary display-6"><strong>Yes</strong></span>
                        {/* <div> */}
                        <input type="checkbox"
                         value="diabeticRetinopathy" 
                        checked={noconsent? false: consent} 
                        className="CheckBox" id="1" 
                        onChange={()=>{setConsent(!consent)
                            setnoConsent(false)
                        }}
                          />  
                          {/* </div> */}
                          </div>
                          <div className="ConcentBox col">
                        <span className="text-primary display-6"><strong>No</strong></span>
                        {/* <div> */}
                        <input type="checkbox"
                         value="diabeticRetinopathy" 
                        checked={consent? false: noconsent} 
                        className="CheckBox" id="2" 
                        onChange={()=>{setConsent(false)
                        setnoConsent(!noconsent)
                        }}
                          />  
                          {/* </div> */}
                          </div>                             
                        </div>
                    </div>
                    </div>               
            </div>              
            <div className="col-xl-2 col-md-6"></div>  
        </div>
        
        <div className="align-items-center px-5 text-center container-fluid">
            <div className="row container">
            <div className="col-xl-4 col-md-6"></div>
            <div className="col-xl-4 col-md-6">
            <br /><br />
                <h4 className="text-primary">Patient name:</h4>
                <input  onChange={e => setName(e.target.value)} 
                 className="form-control" 
                 value={name}
                 type="text" 
                 placeholder="Patient name"/>
            <br />
            </div>
            <div className="col-xl-4 col-md-6"></div>
            </div>           
        </div> 
        <div className="container">      
         <div className="row">
         <div className="col-xl-6 col-md-6 shadow">
            <br />
         <div className="headingCloumn"> 
          Patient Data
        </div>
        
         <table  className="TableStyle">
         
          <tbody className="bodyStyle" >
            <tr className="bodyStyle">
                <td className="ui headerTable bg-light"> Age</td>
                <td className="ui">
                    <form>
                    <input  
                    onChange={e =>{ 
                        if(e.target.value<120){
                        setAge(e.target.value)
                        }else{
                            alert("Invalid age")
                            setAge(0)
                        }
                        }
                    }
                     className="TableInput form-control"  
                     type="number"
                     value={age}
                     name="age"
                      placeholder={"1-120"}
                      />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Sex</td>
                <td className="ui">
                    <form>
                    <select  className="Select form-control" id="sex"  onChange={e => setSex(e.target.value)}>
    <option value="male"  >male</option>
    <option value="femmale" >female</option>
</select>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Visual Acuity</td>
                <td className="ui">
                    <form  className="Insiderow">
                    <span>
                    Left: <input className="TableInputIn form-control" 
                     onChange={e => setvisualAcuityL(e.target.value)}  
                     name="VAleft"  type="text"  />
                    Right: <input className="TableInputIn form-control" 
                    onChange={e => setvisualAcuityR(e.target.value)}
                     name="VAright"  type="text"  />
                    </span>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> height</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" name="height" onChange={e => setHeight(e.target.value)} 
                     type="number"  placeholder="number" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Weight</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control"  onChange={e => setWeight(e.target.value)} name="weigth" type="number"
                     placeholder="number" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Smoker Status</td>
                <td className="ui">
                    <form>
                    <select className="form-control" id="smoker" onChange={e => setSmoker(e.target.value)} >
    <option value="currents">current smoker </option>
    <option value="ex_smoker">ex-smoker</option>
    <option value="non-smoker">non-smoker</option>
</select>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Diabetes type</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setdiaType(e.target.value)} name="daiType"  type="text"  />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Diabates Diagnosis Date</td>
                <td  className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setDiaDate(e.target.value)}  name="daiDate" type="date" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Diabates Medication</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setDiaMed(e.target.value)} name="daiMed"  type="text" placeholder="any" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Hypertensive?</td>
                <td className="ui">
                    <form>
                    <div onChange={e => setHypertensiveL(e.target.value)}>
                      <input className="form-check-input" type="radio" value="yes" name="Hypertensive"/> Yes &nbsp;&nbsp;
                    <input className="form-check-input" type="radio" value="no" name="Hypertensive"/> No
                </div>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Cardiovascular Disease? </td>
                <td className="ui">
                    <form>
                    <div onChange={e => setCardiovascular(e.target.value)}>
                      <input className="form-check-input" type="radio" value="yea" name="Cardiovascular"/> Yes &nbsp;&nbsp;
                    <input className="form-check-input" type="radio" value="no" name="Cardiovascular"/> No
                </div>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> OTC Signal Strength</td>
                <td className="ui">
                <form  className="Insiderow">
                    <span>
                    Left: <input className="TableInputIn form-control"  onChange={e => setOTCleft(e.target.value)} name="OTCSleft"  type="text" placeholder="20" />
                    Right: <input className="TableInputIn form-control"  name="OTCSright" onChange={e => setOTCSright(e.target.value)} type="text" placeholder="20" />
                    </span>
                    </form>
                </td>
            </tr>
          </tbody>
          </table>
          </ div>
          <div className="col-xl-6 col-md-6 shadow">
            <br />
          <div className="headingCloumn2 bg-primary"> 
          Blood Pressure and Biochemistry
            </div>
          <table  className="TableStyle">
          <tbody className="bodyStyle" >
            <tr>
                <td className="ui headerTable bg-light"> Systolic Blood Pressure</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e =>{ 
                        if(e.target.value < 301 && e.target.value > -1){
                        setSBP(e.target.value)
                        }else{
                            alert("Invalid blood pressure")
                            setSBP(0)
                        }
                        }
                    } type="number"
                    value={SBP}
                    name="Sbp" 
                    placeholder={"1-300"}  />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Diastolic Blood Pressure</td>
                <td className="ui">
                    <form>
                    <input  className="TableInput form-control" onChange={e => setDBP(e.target.value)} type="number" placeholder={"number"} name="DBP" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Random Blood Glucose</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setRBG(e.target.value)} type="number"
                     placeholder={"number"} name="RBG"  />
                    </form>
                </td>
            </tr>   
            <tr>
                <td className="ui headerTable"> HbA1C</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setHbA1C(e.target.value)} name="HbA1C"  type="number" placeholder={"number"}  />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Total Cholestrol </td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control"   onChange={e => setCholestrol(e.target.value)}name="cholestrol" type="number" placeholder={"number"}  />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> LDL</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control" onChange={e => setLDL(e.target.value)}  type="number" placeholder={"number"} name="LDL"/>
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light">HDL</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control"  onChange={e => setHDL(e.target.value)}name="HDL"   type="number" placeholder={"number"}  />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable"> Triglycerides</td>
                <td className="ui">
                    <form>
                    <input className="TableInput form-control"  type="number" placeholder={"number"} onChange={e => setTriglycerides(e.target.value)} name="Triglycerides" />
                    </form>
                </td>
            </tr>
            <tr>
                <td className="ui headerTable bg-light"> Albuminuria? </td>
                <td className="ui">
                    <form>
                    <div onChange={e => setAlbuminuria(e.target.value)}>
                      <input className="form-check-input" type="radio" value="yea" name="Albuminuria"/> Yes &nbsp; &nbsp; 
                    <input className="form-check-input" type="radio" value="no" name="Albuminuria"/> No
                </div>
                    </form>
                </td>
            </tr>        
          </tbody>
          </table>
          </div>
          </div>
          <br />
          <div className="ButtonSection text-center">
            <button style={{margin: "10px"}} className="btn btn-primary btn-lg"
            onClick={()=>{handleNext()}}
            >Next</button>
          </div>
          </div>   
          {/* <div>
            <img  className="ImageHeadereye" src={require('../../assests/Images/eyeicon.png')} alt="IASON" />
            </div> */}
         </div>
      );
    }


 
export default Firstform;