import React, {  useState,useEffect } from "react";
import "./RetinoTwo.css";

function RetinoTwo({leftSelection,rightSelection,handleNext,handlePrev}) { 
//state
const [leftChecked1, setleftChecked1] = useState(false);
const [leftChecked2, setleftChecked2] = useState(false);
const [leftChecked3, setleftChecked3] = useState(false);
const [rightChecked1, setrightChecked1] = useState(false);
const [rightChecked2, setrightChecked2] = useState(false);
const [rightChecked3, setrightChecked3] = useState(false);

const [leftPick, setLeftPick] = useState(leftSelection);
const [rightPick, setRightPick] = useState(rightSelection);

useEffect(() => {
  if("R1" in leftPick ){
    if(0 in leftPick.R1){
    setleftChecked1(true)
    }
    if(1 in leftPick.R1){
      setleftChecked2(true)
    }
    if(2 in leftPick.R1){
      setleftChecked3(true)
     }

  }
  if("R1" in rightPick ){
    if(0 in rightPick.R1){
      setrightChecked1(true)
      }
      if(1 in rightPick.R1){
        setrightChecked2(true)
      }
      if(2 in rightPick.R1){
        setrightChecked3(true)
       }
  } 
});

const HandleNextin =()=>{
  var data ={
    Rleft: leftPick,
    Rright: rightPick
  }
 handleNext(data)
 if((rightChecked1 || rightChecked2
  || rightChecked3)){
  localStorage.setItem("RRight2",
   "Background retinopathy present")
 }else{
  localStorage.setItem("RRight2",
   "")
 }
 if((leftChecked1 ||
  leftChecked2 || leftChecked3)){
  localStorage.setItem("RLeft2",
   "Background retinopathy present")
 }
 else{
  localStorage.setItem("RLeft2",
   "")
 }
}
const HandlePrevIn =()=>{
  var data ={
    Rleft: leftPick,
    Rright: rightPick
  }
  handlePrev(data)
  if((rightChecked1 || rightChecked2
    || rightChecked3)){
    localStorage.setItem("RRight2",
     "Background retinopathy present")
   }else{
    localStorage.setItem("RRight2",
     "")
   }
   if((leftChecked1 ||
    leftChecked2 || leftChecked3)){
    localStorage.setItem("RLeft2",
     "Background retinopathy present")
   }else{
      localStorage.setItem("RLeft2",
       "")
     
   }
}
const deleteKey =(obj,keym)=>{
  delete obj[keym]
  return obj
}
const handleChange=(which, pick, position)=>{
  if(which==="right"){  
    if(("R1" in rightPick) || Object.keys(rightPick).length===0){
      //add or remove it
      if("R1" in rightPick){
        var obk =  {...rightPick.R1}
       
        if(rightPick.R1[position] !== undefined){
          
          var obj = deleteKey(obk, position)
         if(position===0){
          setRightPick({R1: obj })
          setrightChecked1(false)
         }
         if(position===1){
          setRightPick({R1: obj })
          setrightChecked2(false)
         }
         if(position===2){
          setRightPick({R1: obj })
          setrightChecked3(false)
         }
         
         if(Object.keys(obj).length===0){
          // var obk2 =  {...rightPick}
          // delete obk2["R1"]
          setRightPick({})
         
         }
          }else{
            if(position===0){

              setRightPick({R1:{...rightPick.R1, 0:pick}}) 
              setrightChecked1(true)
             }
             if(position===1){
              setRightPick({R1:{...rightPick.R1, 1:pick}})
              setrightChecked2(true)
             }
             if(position===2){
              setRightPick({R1: {...rightPick.R1, 2:pick}})
              setrightChecked3(true)
             }
          }
        // setrightPick({})
        // setrightChecked1(false)
      }
     if(Object.keys(rightPick).length===0){
      if(position===0){
        setRightPick({R1:{0:pick}});
        setrightChecked1(true)
       }
       if(position===1){
        setRightPick({R1:{1:pick}});
        setrightChecked2(true)
       }
       if(position===2){
        setRightPick({R1:{2:pick}});
        setrightChecked3(true)
       }
     }
    }
     if(!("R1" in rightPick )&& Object.keys(rightPick).length>0){
      alert("You already selected Retinopathy Grade")
    } 

  }
  if(which==="left"){
    
    if(("R1" in leftPick) || Object.keys(leftPick).length===0){
      //add or remove it
      if("R1" in leftPick){
        var obk =  {...leftPick.R1}
        if(leftPick.R1[position] !== undefined){
          var obj = deleteKey(obk, position)

         if(position===0){
          setLeftPick({R1: obj })
          setleftChecked1(false)
         }
         if(position===1){
          setLeftPick({R1: obj })
          setleftChecked2(false)
         }
         if(position===2){
          setLeftPick({R1: obj })
          setleftChecked3(false)
         }
         if(Object.keys(obj).length===0){
          // var obk2 =  {...leftPick}
          // delete obk2["R1"]
          setLeftPick({})
         }
          }else{
            if(position===0){

              setLeftPick({R1:{...leftPick.R1, 0:pick}}) 
              setleftChecked1(true)
             }
             if(position===1){
              setLeftPick({R1:{...leftPick.R1, 1:pick}})
              setleftChecked2(true)
             }
             if(position===2){
              setLeftPick({R1: {...leftPick.R1, 2:pick}})
              setleftChecked3(true)
             }
          }
        // setLeftPick({})
        // setleftChecked1(false)
      }
     if(Object.keys(leftPick).length===0){
      if(position===0){
        setLeftPick({R1:{0:pick}});
        setleftChecked1(true)
       }
       if(position===1){
        setLeftPick({R1:{1:pick}});
        setleftChecked2(true)
       }
       if(position===2){
        setLeftPick({R1:{2:pick}});
        setleftChecked3(true)
       }
     }
    }
     if(!("R1" in leftPick )&& Object.keys(leftPick).length>0){
      alert("You already selected Retinopathy Grade")
    } 
  }

  
}
    return (  
        <> 
        {console.log(rightPick)}
        <div className="nav navbar-expand-lg sticky-top shadow text-center" style={{marginBottom: "10px", backgroundColor: "#000"}}> 
        <div className="container">
            <p className="display-6 text-light text-center">
              R1:One or more present
          </p>
          </div>
        </div>
        <div className="container">
          <div className="container">
            <div className="row">
            <div className="Image shadow col-xl-4 col-md-6">
            <img  className="HeaderSide img-fluid" style={{ height: "auto"}} src={require('../../assests/Images/ret2.jpg')} alt="retina" />
            <div className="Caption text-dark">
              <h5>Source: IDRiD Dataset</h5>
            </div>
            </div>
          <div className="SideTable col-xl-8 col-md-6">
            <div className="container">
            <table className="shadow" style={{borderRadius: "10px", margin: "5px"}}>
              <tr style={{backgroundColor: "#000"}}>
                <th>
                Criteria
                </th>
                <th>Right Eye
               (tick if present)</th>
               <th>Left Eye
               (tick if present)</th>
              </tr>
              <tr>
                <td className="text-dark">Microaneurysm(s)</td>
                <td><form>
                <input type="checkbox"  checked={rightChecked1} 
                className="CheckBox" value="Microaneurysm" id="myCheckRight" 
                onChange={(e) => {handleChange("right",e.target.value,0)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox" 
                 value="Microaneurysm" checked={leftChecked1}
                  className="CheckBox" id="myCheckleft" 
                onChange={(e) => {handleChange("left",e.target.value,0)}}/>
                  </form></td>
              </tr>
              <tr style={{backgroundColor: "#e9e9e9"}}>
                <td className="text-dark">Retinal haemorrhage(s)</td>
                <td><form>
                <input type="checkbox" value="haemorrhage" 
                 checked={rightChecked2} 
                className="CheckBox" id="myCheckRight" 
                onChange={(e) => {handleChange("right", e.target.value,1)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox"  value="haemorrhage"
                 checked={leftChecked2} className="CheckBox" id="myCheckleft" 
                onChange={(e) => {handleChange("left",e.target.value,1)}}/>
               
                  </form></td>
              </tr>
              <tr>
                <td className="text-dark">Any Exudates</td>
                <td><form>
                <input type="checkbox" value="Exudates"  checked={rightChecked3} 
                className="CheckBox" id="myCheckRight" 
                onChange={(e) => {handleChange("right",e.target.value,2)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox"  value="Exudates" checked={leftChecked3} 
                className="CheckBox" id="myCheckleft" 
                onChange={(e) => {handleChange("left",e.target.value,2)}}/>
               
                  </form></td>
              </tr>
              
            </table>
            <span >
              <span  className="RigthAnswer bg-primary text-light">Right eye:</span>
              <span className="AnswerDisplay">{(rightChecked1 || rightChecked2
               || rightChecked3)?"Background retinopathy present":""}
               </span>
            </span>
            <span>
              <span  className="LeftAnswer bg-dark text-light">Left eye:&nbsp;&nbsp;&nbsp;</span>
              <span  className="AnswerDisplay">{(leftChecked1 ||
                 leftChecked2 || leftChecked3)?"Background retinopathy present":""}
              </span>
            </span>
            </div>
           
          </div>
          
            </div>
          </div>
        </div>
        <div className="container">
            <div className="row">
            <div className="col-xl-4 col-md-6"></div>
            <div className="col-xl-4 col-md-6 text-center">

            
            <button className="btn btn btn-dark"
            onClick={()=>{HandlePrevIn()}}
            >Previous</button>&nbsp;
            <button className="btn btn btn-primary"
            onClick={()=>{HandleNextin()}}
            >Next</button>
            </div>
            <div className="col-xl-4 col-md-6"></div>
          </div>
          </div>
        </>
        
      );

    }


 
export default RetinoTwo;