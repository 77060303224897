import React, {  useState,useEffect } from "react";
import "./MScreen.css";

function MScreen({leftSelection,rightSelection,handleNext,handlePrev}) { 
//state
const [leftChecked1, setleftChecked1] = useState(false);
const [leftChecked2, setleftChecked2] = useState(false);
const [leftChecked3, setleftChecked3] = useState(false);
const [rightChecked1, setrightChecked1] = useState(false);
const [rightChecked2, setrightChecked2] = useState(false);
const [rightChecked3, setrightChecked3] = useState(false);

const [leftPick, setLeftPick] = useState(leftSelection);
const [rightPick, setRightPick] = useState(rightSelection);

useEffect(() => {
  if("M1" in leftPick ){
    if(0 in leftPick.M1){
     setleftChecked1(true)
    }
    if(1 in leftPick.M1){
      setleftChecked2(true)
    }
    if(2 in leftPick.M1){
      setleftChecked3(true)
    }

  }
  if("M1" in rightPick ){
    if(0 in rightPick.M1){
      setrightChecked1(true)
      }
      if(1 in rightPick.M1){
        setrightChecked2(true)
      }
      if(2 in rightPick.M1){
        setrightChecked3(true)
      }

  } 
});

const HandleNextin =()=>{
  var data ={
    Mleft: leftPick,
    Mright: rightPick
  }
 handleNext(data)
 if((rightChecked1 || rightChecked2 || rightChecked3)){
  localStorage.setItem("MRight",
  "High risk Maculopathy present")
 }else{
  localStorage.setItem("MRight",
  "")}
 if((leftChecked1 || leftChecked2|| leftChecked3)){
  localStorage.setItem("MLeft",
   "High risk Maculopathy present")
 }else{
  localStorage.setItem("MLeft",
  "")}
 
}
const HandlePrevIn =()=>{
  var data ={
    Mleft: leftPick,
    Mright: rightPick
  }
  handlePrev(data)
  if((rightChecked1 || rightChecked2 || rightChecked3)){
    localStorage.setItem("MRight",
    "High risk Maculopathy present")
   }else{
    localStorage.setItem("MRight",
    "")}
   if((leftChecked1 || leftChecked2|| leftChecked3)){
    localStorage.setItem("MLeft",
     "High risk Maculopathy present")
   }else{
    localStorage.setItem("MLeft",
    "")}

}

const deleteKey =(obj,keym)=>{
  delete obj[keym]
  return obj
}
const handleChange=(which, pick, position)=>{
  if(which==="right"){  
    if(("M1" in rightPick) || Object.keys(rightPick).length===0){
      //add or remove it
      if("M1" in rightPick){
        var obk =  {...rightPick.M1}
       
        if(rightPick.M1[position] !== undefined){
          
          var obj = deleteKey(obk, position)
         if(position===0){
          setRightPick({M1: obj })
          setrightChecked1(false)
         }
         if(position===1){
          setRightPick({M1: obj })
          setrightChecked2(false)
         }
        
         if(position===2){
          setRightPick({M1: obj })
          setrightChecked3(false)
         }
         if(Object.keys(obj).length===0){
          
          setRightPick({})
         }
          }else{
            if(position===0){

              setRightPick({M1:{...rightPick.M1, 0:pick}}) 
              setrightChecked1(true)
             }
             if(position===1){
              setRightPick({M1:{...rightPick.M1, 1:pick}})
              setrightChecked2(true)
             }
             if(position===2){
              setRightPick({M1:{...rightPick.M1, 2:pick}})
              setrightChecked3(true)
             }
            
          }
        
      }
     if(Object.keys(rightPick).length===0){
      if(position===0){
        setRightPick({M1:{0:pick}});
        setrightChecked1(true)
       }
       if(position===1){
        setRightPick({M1:{1:pick}});
        setrightChecked2(true)
       }
       if(position===2){
        setRightPick({M1:{2:pick}});
        setrightChecked3(true)
       }
       
     }
    }
     if(!("M1" in rightPick )&& Object.keys(rightPick).length>0){
      alert("Can't add to right eye")
    } 

  }
  if(which==="left"){
    
    if(("M1" in leftPick) || Object.keys(leftPick).length===0){
      //add or remove it
      if("M1" in leftPick){
        var obk =  {...leftPick.M1}
       
        if(leftPick.M1[position] !== undefined){
        
          var obj = deleteKey(obk, position)
         if(position===0){
          setLeftPick({M1: obj })
          setleftChecked1(false)
         }
         if(position===1){
          setLeftPick({M1: obj })
          setleftChecked2(false)
         }
         if(position===2){
          setLeftPick({M1: obj })
          setleftChecked3(false)
         }
         if(Object.keys(obj).length===0){
          
          setLeftPick({})
         }
          }else{
            if(position===0){

              setLeftPick({M1:{...leftPick.M1, 0:pick}}) 
              setleftChecked1(true)
             }
             if(position===1){
              setLeftPick({M1:{...leftPick.M1, 1:pick}})
              setleftChecked2(true)
             }
             if(position===2){
              setLeftPick({M1:{...leftPick.M1, 2:pick}})
              setleftChecked3(true)
             }
             
          }
        // setLeftPick({})
        // setleftChecked1(false)
      }
     if(Object.keys(leftPick).length===0){
      if(position===0){
        setLeftPick({M1:{0:pick}});
        setleftChecked1(true)
       }
       if(position===1){
        setLeftPick({M1:{1:pick}});
        setleftChecked2(true)
       }
       if(position===2){
        setLeftPick({M1:{2:pick}});
        setleftChecked3(true)
       }
       
     }
    }
     if(!("M1" in leftPick )&& Object.keys(leftPick).length>0){
      alert("Can't add to left eye")
    } 
  }
  
}
  

    return (  
        <> 
      <div className="nav navbar-expand-lg sticky-top bg-gradient shadow text-center" style={{marginBottom: "10px", backgroundColor: "#800080"}}>  
        <div className="container">
            <p className="display-6 text-light text-center">
            M1: One or more of the following present
            <br />
            M0: All absent
              </p>
          </div>
        </div>
        <div className="container">
          <div className="container">
            <div className="row">
            <div className="Image shadow col-xl-4 col-md-6">
          <img  className="HeaderSide img-fluid" style={{ height: "auto"}} src={require('../../assests/Images/m2.jpg')} alt="retina" />
          <div className="Caption text-primary">
            <h5>Source: IDRiD Dataset</h5>
          </div>
          </div>
          <div className="SideTable col-xl-8 col-md-6">
            <div className="container">
            <table className="shadow"  style={{borderRadius: "10px", margin: "5px"}}>
              <tr style={{backgroundColor: "#800080"}}>
                <th>
                Criteria
                </th >
                <th>Right Eye
               (tick if present)</th>
               <th>Left Eye
               (tick if present)</th>
              </tr>
              <tr>
                <td style={{color: "#800080"}}><strong>Macula exudate ≤1 DD of the fovea</strong></td>
                <td><form>
                <input type="checkbox" 
                  checked={rightChecked1}
                className="CheckBox" id="myCheckRight" 
                value="MaculaExudate"
                onChange={(e) =>
                  {handleChange("right",e.target.value,0)}
                 
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox"
                  checked={leftChecked1}
                 value="MaculaExudate"
                  className="CheckBox" id="myCheckleft" 
                onChange={(e) => 
                  {handleChange("left",e.target.value,0)}}/>
               
                  </form></td>
              </tr>
              <tr style={{backgroundColor: "#e9e9e9"}}>
                <td style={{color: "#800080"}}><strong>Group of macula exudates ≥1/2 a disc area (DA)</strong></td>
                <td><form>
                <input type="checkbox" 
                 value="macula exudates ≥1/2"
                 checked={rightChecked2}
                className="CheckBox" id="myCheckRight" 
                onChange={(e) => 
                  {handleChange("right",e.target.value,1)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox" 
                value="macula exudates ≥1/2"
                checked={leftChecked2}
                 className="CheckBox" id="myCheckleft" 
                onChange={(e) => 
                  {handleChange("left",e.target.value,1)}}/>
               
                  </form></td>
              </tr>
              <tr>
                <td style={{color: "#800080"}}><strong>Visual acuity ≤6/12 AND microorganism  ≤1 DD from fovea</strong></td>
                <td><form>
                <input type="checkbox"  
                value="Visual acuity ≤6/12 AND microorganism  ≤1 DD"
                checked={rightChecked3} 
                className="CheckBox" id="myCheckRight" 
                onChange={(e) => {handleChange("right",e.target.value,2)}
                  }/>
                    </form></td>
                <td><form>
                <input type="checkbox" 
                 value="Visual acuity ≤6/12 AND microorganism  ≤1 DD"
                 checked={leftChecked3} 
                 className="CheckBox" id="myCheckleft" 
                onChange={(e) => 
                {handleChange("left",e.target.value,2)}}/>
               
                  </form></td>
              </tr>
              
            </table>
            <span ><span  className="RigthAnswer bg-primary text-light">Right eye:</span>
            <span className="AnswerDisplay">{(rightChecked1 || rightChecked2 || rightChecked3)?
            "High risk Maculopathy present":"low risk Maculopathy present "}
            </span></span>
            <span><span  className="LeftAnswer bg-dark text-light">Left eye:&nbsp;&nbsp;&nbsp;</span>
            <span  className="AnswerDisplay">{(leftChecked1 || leftChecked2|| leftChecked3)?
            "High risk Maculopathy present":"low risk Maculopathy present "}
            </span></span>
            </div>           
          </div>
          <div className="container">
                <div className="row">
                <div className="col-xl-4 col-md-6"></div>
                <div className="col-xl-4 col-md-6 text-center">
                <button className="btn btn btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button className="btn btn btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Next</button>
                </div>
                
                
                
                <div className="col-xl-4 col-md-6"></div>
              </div>
            </div>
            </div>
          </div>
          
        </div>
        </>
        
      );

}


 
export default MScreen;