import React, {  useState,useEffect } from "react";
import "./FinalGrading.css";


function FinalGrading({handleNext,handlePrev,docInfor}) { 
  const [nameC, setNameC] = useState(docInfor?.name ? docInfor?.name: "")
    const [plan, setPlan] = useState(docInfor?.Medicalplan ? docInfor?.Medicalplan: "")
    const [sign,  setSign] =useState(docInfor?.sign ? docInfor?.sign: "");

    const [RRight,setRRight] = useState("")
    const [RLeft,setRLeft] = useState("")
    const [MRight,setMRight] = useState("")
    const [MLeft,setMLeft] = useState("")
    const [ORight,set0Right] = useState("")
    const [OLeft,setOLeft] = useState("")

//state
useEffect(() => {
 setRRight(localStorage.getItem("RRight"))
 setRLeft(localStorage.getItem("RLeft"))
 setMRight(localStorage.getItem("MRight"))
 setMLeft( localStorage.getItem("MLeft"))
 set0Right( localStorage.getItem("ORight"))
 setOLeft( localStorage.getItem("OLeft"))
});
const HandleNextin =()=>{
  const obj ={
    name:nameC,
    Medicalplan:plan,
    sign 
  }
 handleNext(obj)
}

const HandlePrevIn =()=>{
  const obj ={
    name:nameC,
    Medicalplan:plan,
    sign 
  }
  handlePrev(obj)
}

    return (  
        <>
        <div className="nav navbar-expand-lg sticky-top bg-primary bg-gradient shadow text-center" style={{marginBottom: "10px"}}>  
        <div className="container">
            <p className="display-6 text-light text-center">
              <strong>Final Grading</strong>
              </p>
          </div>
        </div> 
        <div className="container">
        <div className="row">
            <div className="col-xl-2 col-md-6"></div>
            <div className="col-xl-8 col-md-6">
              <table className="table shadow" style={{borderRadius: "10px", margin: "5px"}} >
              <tr className="bg-info bg-gradient">
                <th>Final Grading </th>
                <th>Right Eye</th>
               <th>Left Eye</th>
              </tr>
        <tr>
                <td className="RowHead">Retinopathy (Highest grade per eye)</td>
                <td className="Row">
                 {localStorage.getItem("RRight1") && localStorage.getItem("RRight1")}
                 {localStorage.getItem("RRight2") && localStorage.getItem("RRight2")}
                 {localStorage.getItem("RRight3") && localStorage.getItem("RRight3")}
                 {localStorage.getItem("RRight4") && localStorage.getItem("RRight4")}
                 {localStorage.getItem("RRight5") && localStorage.getItem("RRight5")}
                </td>
                <td className="Row">
                   {localStorage.getItem("RLeft1") && localStorage.getItem("RLeft1")}
                 {localStorage.getItem("RLeft2") && localStorage.getItem("RLeft2")}
                 {localStorage.getItem("RLeft3") && localStorage.getItem("RLeft3")}
                 {localStorage.getItem("RLeft4") && localStorage.getItem("RLeft4")}
                 {localStorage.getItem("RLeft5") && localStorage.getItem("RLeft5")}</td>
           </tr>
           <tr>
                <td className="RowHead2">Maculopathy</td>
                <td>
                {MRight}
                    </td>
                <td>   
                {MLeft}
               </td>
              </tr>
              <tr>
                <td className="RowHead3">OCT</td>
                <td className="Row">
                {localStorage.getItem("ORightp") && localStorage.getItem("ORightp")}
                 {localStorage.getItem("ORightn") && localStorage.getItem("ORightn")}
                 {localStorage.getItem("ORightb") && localStorage.getItem("ORightb")}
                </td>
                <td className="Row">
                {localStorage.getItem("OLeftp") && localStorage.getItem("OLeftp")}
                 {localStorage.getItem("OLeftn") && localStorage.getItem("OLeftn")}
                 {localStorage.getItem("OLeftb") && localStorage.getItem("OLeftb")}
                </td>
              </tr>
             
        </table>
        <br />
        <div className="shadow container">
          
          <div className="row">
                <label className="">Management Plan 
                (Either continue annual screening, OCT surveillance 6-12m or Refer)</label>
                <div className="">
                <form>
                    <textarea className="form-control"
                     name="plan"
                     value={plan}
                      onChange={e => setPlan(e.target.value)} 
                       type="text" />
                    </form>
                </div>
                
          </div>
              <div className="row">
                <label className="">Name of Clinician</label>
                <div className="">
                <form>
                    <input className="form-control"
                     name="nameC"
                     value={nameC}
                      onChange={e => setNameC(e.target.value)} 
                      type="text" />
                    </form>
                </div>
                
              </div>
              <div className="row">
                <label className="">Signature</label>
                <div className="">
                <form>
                    <textarea className="form-control"
                     name="sign" 
                     value={sign}
                     onChange={e => setSign(e.target.value)} 
                      type="text" />
                    </form>
                </div>
                
              </div>
              <br />
          
        </div>
            </div>
            <div className="col-2"></div>

          </div>
        </div>
        {/* <div className="CommonWid"> */}
      {/*  </div> */}
        <br />
        <div className="container">
                <div className="row">
                <div className="col-xl-4 col-md-6"></div>
                <div className="col-xl-4 col-md-6 text-center">
                <button className="btn btn btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button className="btn btn btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Next</button>
                </div>
                
                
                
                <div className="col-xl-4 col-md-6"></div>
              </div>
            </div>
            <br /><br />
          
        </>
        
      );

    }


 
export default FinalGrading;