import React, {  useState } from "react";
import module from "./DefinitionPage.css";

function DefinitionsPage({handleNext,handlePrev}) { 
//state
const HandleNextin =()=>{
 handleNext()
}
const HandlePrevIn =()=>{
  handlePrev()
}
    return ( 
      <> 
      <div className="nav navbar-expand-lg sticky-top bg-primary bg-gradient shadow text-center" style={{marginBottom: "10px"}}>  
        <div className="container">
            <p className="display-6 text-light text-center">
            Definitions Page
              </p>
          </div>
        </div>
        
        <div  className="container"> 
        {/* <table className= {module.Directiontable}> */}
        <table className="table shadow" style={{borderRadius: "10px", margin: "5px"}} >
        <tr className="bg-info bg-gradient">
                <th className="">OCT Grading </th>
                <th className="">Criteria</th>
               <th className="">Recomendation</th>
        </tr>
        <tr>
                <td className="text-light" style={{backgroundColor: "#000"}}><strong>OCT Positive If present then grade = M1</strong></td>
                <td className="Row">
                <p><strong>Intraretinal cysts and one or more of:</strong></p>
                    <ul>
                        <li> Central Retinal Thickness (CRT) > 250μm* </li>
                        <li> A change in the foveal contour with intraretinal cysts </li>
                        <li>Retinal thickening ≥1/2 a disc area, the edge of which is ≤1 DD of the central fovea</li>
                        <li>Retinal thickening ≥1 disc area within the macula</li>
                    </ul>
                    </td>
                <td className="rowEven"><div>Refer to ophthalmology</div></td>
       </tr>
       <tr>
                <td className="text-light" style={{backgroundColor: "#800080"}}><strong>OCT Borderline If present then grade = M1</strong></td>
                <td className="rowEven">
                
                    <ul>
                        <li> Intraretinal cysts but no change in foveal contour and CRT ≤250μm*</li>
                        <li> Retinal thickening less than 1 disc area within the macula </li>
                       
                    </ul>
                    </td>
                <td className="Row"><div>OCT digital surveillance review in 3 or 6 months (if R1 or R3S)</div></td>
              </tr>
              <tr>
                <td className="text-light" style={{backgroundColor: "#008000"}}><strong>OCT Negative</strong></td>
                <td className="Row">
                
                    <ul>
                        <li> None of the above criteria met</li>
                        <li>[and]</li>
                        <li> Normal CRT </li>
                       
                    </ul>
                    </td>
                <td className="rowEven"><div>Screen Annually (if R1 or R3S)</div></td>
              </tr>
        </table>
        <br />
        <div className="BottomText text-dark text-center">
          <strong>
          *Normal CRT differs depending on machine used, check reference values for your OCT
          </strong>
        </div>
        <br />
        <div className="container">
                <div className="row">
                <div className="col-xl-4 col-md-6"></div>
                <div className="col-xl-4 col-md-6 text-center">
                <button className="btn btn btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button className="btn btn btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Next</button>
                </div>
                
                
                
                <div className="col-xl-4 col-md-6"></div>
              </div>
            </div>
            <br /><br />
        </div>
      </>
        
      );

    }


 
export default DefinitionsPage;