import React, {  useState } from "react";
import "./LastPage.css";

function LastPage({handlePrint,handlePrev,handleFormSubmission}) { 
//state

const HandleNextin =()=>{
 
    handlePrint({})
}
const HandlePrevIn =()=>{
 
  handlePrev({})
}  

    return (  
        <> 
        <div className="nav navbar-expand-lg sticky-top bg-dark bg-gradient shadow text-center" style={{marginBottom: "10px"}}>  
        <div className="container">
            <p className="display-6 text-light text-center">
              <strong>Submit</strong>
              </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-md-6"></div>
            <div className="col-xl-8 col-md-6">
              <div className="shadow">
                  <img  className="img-fluid" style={{ height: "auto"}} src={require('../../assests/Images/lastTable.png')} alt="retina" />
              </div>
            </div>
            <div className="col-xl-2 col-md-6"></div>
          </div>
        </div>
        <br />
        <div className="container">
                <div className="row">
                <div className="col-xl-3 col-md-6"></div>
                <div className="col-xl-6 col-md-6 text-center">
                <button style={{margin: "2px"}}className="btn btn-lg btn-dark"
                  onClick={()=>{HandlePrevIn()}}
                  >Previous</button>&nbsp;
                  <button style={{margin: "2px"}}className="btn btn-lg btn-primary"
                  onClick={()=>{HandleNextin()}}
                  >Print</button>&nbsp;
                  <button style={{margin: "2px"}}className="btn btn-lg btn-success"
                  onClick={()=>{handleFormSubmission()}}
                  >Click to Submit Form</button>
                </div>
                <div className="col-xl-3 col-md-6"></div>
              </div>
            </div>
            <br /><br />
          {/* <img  className="Machine" src={require('../../assests/Images/machine.jpg')} alt="machine" /> */}
        </>
        
      );

    }


 
export default LastPage;